/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { RiSendPlane2Line, RiMailFill, RiPhoneFill } from "react-icons/ri"
import Layout from "../components/layout"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
        map
        contactInfo {
          contactInfoVisibility
          email
          phoneNumber
        }
        mapVisibility
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({ data }) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your page data
  const { frontmatter, html } = markdownRemark
  const contactDetails = frontmatter.contactInfo

  const contactForm = (
    <div sx={contactStyles.wrapper}>
      <form
        sx={contactStyles.contactForm}
        action="/thanks"
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <input type="text" name="name" placeholder="Name" required />
        </p>
        <p>
          <input type="email" name="email" placeholder="Email" required />
        </p>
        <p>
          <input type="text" name="subject" placeholder="Subject" required />
        </p>
        <div sx={contactStyles.wrap}>
          <div>
            <textarea name="message" placeholder="Message" required></textarea>
            <div sx={contactStyles.sendButton}>
              <button
                sx={{
                  variant: "variants.button.primary",
                  borderRadius: "12px",
                }}
                type="submit"
              >
                Send Message
                <RiSendPlane2Line sx={{ ml: 2 }} />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )

  const contactInformation = (
    <div sx={contactStyles.contactInformation}>
      <div>
        <h1 sx={contactStyles.title}>{frontmatter.title}</h1>
        <div
          sx={contactStyles.description}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      {frontmatter.contactInfo.contactInfoVisibility === "YES" ? (
        <div>
          {contactDetails.email ? (
            <a
              href={"mailto:" + contactDetails.email}
              sx={contactStyles.infoAction}
            >
              <div sx={contactStyles.contactDetail}>
                <div>
                  <RiMailFill sx={contactStyles.infoIcon} />
                </div>
                <p sx={contactStyles.contactInfo}>{contactDetails.email}</p>
              </div>
            </a>
          ) : (
            ""
          )}

          {contactDetails.phoneNumber ? (
            <a
              href={"tel:" + contactDetails.phoneNumber}
              sx={contactStyles.infoAction}
            >
              <div sx={contactStyles.contactDetail}>
                <div>
                  <RiPhoneFill sx={contactStyles.infoIcon} />
                </div>
                <p sx={contactStyles.contactInfo}>
                  {contactDetails.phoneNumber}
                </p>
              </div>
            </a>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )

  const map = frontmatter.map ? frontmatter.map : ""
  return (
    <Layout sx={contactStyles.contactPage}>
      <Seo
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <div sx={contactStyles.contactBody}>
        <div sx={contactStyles.contactContainer}>
          {contactInformation}
          {contactForm}
        </div>
        {frontmatter.mapVisibility === "YES" ? (
          <div
            sx={contactStyles.map}
            dangerouslySetInnerHTML={{ __html: map }}
          />
        ) : (
          ""
        )}
      </div>
    </Layout>
  )
}

export default Contact

const contactStyles = {
  contactPage: {
    variant: "variants.section",
    pb: 0,
    minHeight: "75vh",
  },
  contactBody: {
    variant: "variants.container",
  },
  contactContainer: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr"],
    gap: ["30px", "60px"],
    alignItems: "center",
    maxWidth: "1280px",
    m: "0 auto",
    mb: 7,
  },
  infoAction: {
    display: "block",
    mt: 5,
  },
  wrapper: {
    maxWidth: "100%",
  },
  title: {
    m: 0,
    color: "global.headingColor",
    fontSize: [
      "32px !important",
      "48px !important",
      "48px !important",
      "64px !important",
    ],
  },
  description: {
    display: "inline-block",
    m: 0,
    fontSize: [3, 4],
    color: "global.mutedColor",
    fontWeight: "light",
  },
  contactForm: {
    p: {
      m: 0,
    },
    input: {
      width: "100%",
      maxWidth: "100%",
      mb: 5,
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
    textarea: {
      minHeight: "200px",
      fontFamily: "inherit",
      width: "100%",
      maxWidth: "100%",
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      display: "block",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
  },
  contactInformation: {
    bg: "contact.infoBgColor",
    borderRadius: "12px",
    border: "2px solid",
    borderColor: "contact.borderColor",
    p: 4,
    alignSelf: "self-start",
  },
  contactDetail: {
    display: ["block", "flex", "flex", "flex"],
    alignItems: "top",
  },
  infoIcon: {
    fontSize: 6,
    color: "contact.textColor",
    mr: 3,
  },
  contactInfo: {
    fontSize: 3,
    m: 0,
    color: "contact.textColor",
  },
  map: {
    maxWidth: "1280px",
    m: "0 auto",
    mb: 7,
    borderColor: "contact.borderColor",
    iframe: {
      mb: "-7px",
      border: "1px solid",
      borderRadius: "12px",
      overflow: "hidden",
    },
  },
  sendButton: {
    mt: 5,
  },
  button: {
    color: "button.primaryTextColor",
    bg: "button.primaryTextHoverColor",
    ":hover": {
      color: "button.primaryBgColor",
      bg: "button.primaryBgHoverColor",
    },
  },
  wrap: {
    position: "relative",
    textarea: {
      mb: 0,
    },
  },
}
